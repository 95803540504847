<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">
               {{ $t('elearning_tim.allowance') }} {{ $t('globalTrans.insert') }}
            </h4>
          </template>
          <template v-slot:headerAction>
            <router-link to="allowance" :class="'btn btn-success text-light mr-1'">{{ $t('elearning_tim.allowance') }} {{ $t('globalTrans.list') }}</router-link>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form id="form" @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                    <b-row>
                      <!-- <b-col lg="6" sm="6">
                        <ValidationProvider name="Circular Memo No" vid="circular_memo_no" rules="required">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="circular_memo_no"
                              slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                                plain
                                v-model="formData.circular_memo_no"
                                :options="circularList"
                                id="circular_memo_no"
                                :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col> -->
                    </b-row>
                    <b-overlay :show="load">
                      <b-row>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="fiscal_year_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.fiscal_year')}} <span class="text-danger">*</span>
                              </template>
                              <!-- <b-form-select
                                  plain
                                  v-model="formData.fiscal_year_id"
                                  :options="fiscalYearList"
                                  id="fiscal_year_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select> -->
                                  <v-select
                                      id="fiscal_year_id"
                                      v-model="formData.fiscal_year_id"
                                      :reduce="op => op.value"
                                      :options="fiscalYearList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6" v-if="$store.state.Auth.activeRoleId === 1">
                          <ValidationProvider name="Organization"  vid="org_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="org_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.organization')}} <span class="text-danger">*</span>
                              </template>
                              <!-- <b-form-select
                                  plain
                                  v-model="formData.org_id"
                                  :options="orgList"
                                  id="org_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select> -->
                                  <v-select
                                      id="org_id"
                                      v-model="formData.org_id"
                                      :reduce="op => op.value"
                                      :options="orgList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <!-- <b-col lg="6" sm="6">
                          <ValidationProvider name="Office Type" vid="office_type_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="office_type_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.office_type')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="formData.office_type_id"
                                  :options="officeTypeList"
                                  id="office_type_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Office" vid="office_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="office_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('globalTrans.office')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="formData.office_id"
                                  :options="officeList"
                                  id="office_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col> -->
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Training Type"  vid="training_type_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="training_type_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.training_type')}} <span class="text-danger">*</span>
                              </template>
                              <!-- <b-form-select
                                plain
                                v-model="formData.training_type_id"
                                :options="trainingTypeList"
                                id="training_type_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select> -->
                                  <v-select
                                      id="training_type_id"
                                      v-model="formData.training_type_id"
                                      :reduce="op => op.value"
                                      :options="trainingTypeList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Training Category" vid="training_category_id" rules="required|min_value:1">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="training_category_id"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.training_category')}} <span class="text-danger">*</span>
                              </template>
                              <!-- <b-form-select
                                plain
                                v-model="formData.training_category_id"
                                :options="trainingCategoryList"
                                id="training_category_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select> -->
                                  <v-select
                                      id="training_category_id"
                                      v-model="formData.training_category_id"
                                      :reduce="op => op.value"
                                      :options="trainingCategoryList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Training Title" vid="training_title_id" rules="required|min_value:1">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="training_title_id"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.training_title')}} <span class="text-danger">*</span>
                              </template>
                              <!-- <b-form-select
                                plain
                                v-model="formData.training_title_id"
                                :options="trainingTitleList"
                                id="training_title_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select> -->
                                  <v-select
                                      id="training_title_id"
                                      v-model="formData.training_title_id"
                                      :reduce="op => op.value"
                                      :options="trainingTitleList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Batch No" vid="batch_no" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="batch_no"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_iabm.batch_no')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="formData.batch_no"
                                  :options="batchList"
                                  id="batch_no"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Circular Memo No" vid="circular_memo_no" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="circular_memo_no"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="formData.circular_memo_no"
                                  :options="circularList"
                                  id="circular_memo_no"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                  <b-form-select-option value=''>{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Honour Amount" vid="honour_amount">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="honour_amount"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_tim.allowance')}}
                              </template>
                              <b-form-input
                                v-model="formData.honour_amount"
                                @input="vatAddAll"
                                :placeholder="$t('globalTrans.honour_amount')"
                                :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6" v-if="formData.org_id === 12">
                          <ValidationProvider name="Honour Amount" vid="honour_amount">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="honour_amount"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_tim.field_visit_amount')}}
                              </template>
                              <b-form-input
                                v-model="formData.field_visit_amount"
                                @input="vatAddAll"
                                :placeholder="$t('globalTrans.honour_amount')"
                                :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6" v-if="formData.org_id === 12">
                          <ValidationProvider name="Honour Amount" vid="honour_amount">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="honour_amount"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_tim.field_visit_day')}}
                              </template>
                              <b-form-input
                                v-model="formData.field_visit_day"
                                @input="vatAddAll"
                                :placeholder="$t('globalTrans.honour_amount')"
                                :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Tax" vid="vat_tax">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="vat_tax"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('globalTrans.income_tax') + '(%)'}}
                              </template>
                              <b-form-input
                                v-model="formData.vat_tax"
                                @input="vatAddAll"
                                :placeholder="$t('globalTrans.income_tax')"
                                :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Date" vid="date">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="date"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('globalTrans.select_date')}}
                              </template>
                              <b-form-input
                                class="fromDate"
                                v-model="formData.payment_date"
                                :placeholder="$t('globalTrans.select_date')"
                                :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-row v-if="training_start_date">
                          <div class="col-md-12"><span class="col-md-12" style="font-weight: bold;">{{ $t('elearning_tpm.training_duration') }} {{'('}}{{ training_start_date | dateFormat }} {{'-'}} {{ training_end_date | dateFormat }}{{ ')' }}</span></div>
                        </b-row>
                      </b-row>
                    </b-overlay>
                    <b-row>
                      <div class="col-md-12">
                        <fieldset class="p-2 w-100">
                          <legend class="px-2 w-50 shadow-sm">{{$t('elearning_tim.allowance')}}</legend>
                            <b-overlay :show="loading">
                              <table class="table" style="width:100%;font-size: 12px">
                                <thead class="thead">
                                  <tr>
                                    <th class="text-center" style="width:7%">{{$t('globalTrans.sl_no')}}</th>
                                    <th style="width:12%">{{$t('globalTrans.name')}}</th>
                                    <th style="width:12%">{{$t('globalTrans.designation')}}</th>
                                    <!-- <th style="width:12%">{{$t('elearning_tim.posting_office')}}</th> -->
                                    <!-- <th style="width:12%">{{$t('globalTrans.organization')}}</th> -->
                                    <th style="width:7%">{{$t('globalTrans.number_of_days')}}</th>
                                    <th style="width:12%">{{$t('elearning_tim.allowance')}}</th>
                                    <th style="width:7%" v-if="formData.org_id === 12">{{$t('elearning_tim.field_visit_amount')}}</th>
                                    <th style="width:7%" v-if="formData.org_id === 12">{{$t('elearning_tim.field_visit_day')}}</th>
                                    <th style="width:7%">{{$t('globalTrans.total_amount')}}</th>
                                    <th style="width:7%">{{$t('globalTrans.income_tax_amount')}}</th>
                                    <th style="width:7%">{{$t('globalTrans.detuction')}}</th>
                                    <th style="width:10%">{{$t('globalTrans.net_amount')}}</th>
                                  </tr>
                                </thead>
                                <tr v-for="(train,index) in formData.payments" :key="train.id">
                                  <!-- {{train.total_days}} -->
                                  <td class="text-center">{{$n(index+1)}}</td>
                                  <td>
                                    <div style="font-size: 14px;">
                                      {{ ($i18n.locale==='bn') ? train.name_bn : train.name }}
                                    </div>
                                  </td>
                                  <td>
                                    <div style="font-size: 14px;">
                                      <span v-if="train.designation_id && train.not_here_designation == 0">
                                        {{ ($i18n.locale==='bn') ? train.designation_name_bn : train.designation_name }}
                                      </span>
                                      <span v-if="train.not_here_designation == 1">
                                        {{ ($i18n.locale==='bn') ? train.designation_bn : train.designation_en }}
                                      </span>
                                    </div>
                                  </td>
                                  <!-- <td>{{ (($i18n.locale==='bn') ? train.office_bn : train.office) + ', ' + (($i18n.locale==='bn') ? train.org_bn : train.org) }}</td>
                                  <td>{{ ($i18n.locale==='bn') ? train.org_bn : train.org }}</td> -->
                                  <td>
                                    <b-form-input
                                      rows="2"
                                      v-model="train.total_days"
                                      :placeholder="$t('globalTrans.number_of_days')"
                                      @input="paymentAmountUpdate(train)"
                                    ></b-form-input>
                                    <small v-if="paymentErrors && paymentErrors['payments.' + index + '.total_days']" class="text-danger">
                                      {{ paymentErrors['payments.'+index+'.total_days'] }}
                                    </small>
                                  </td>
                                  <td>
                                    <b-form-input
                                      rows="2"
                                      v-model="train.honour_amount"
                                      disabled
                                      :placeholder="$t('elearning_tim.allowance')"
                                      @input="paymentAmountUpdate(train)"
                                    ></b-form-input>
                                    <small v-if="paymentErrors && paymentErrors['payments.' + index + '.honour_amount']" class="text-danger">
                                      {{ paymentErrors['payments.'+index+'.honour_amount'] }}
                                    </small>
                                  </td>
                                  <td v-if="formData.org_id === 12">
                                    <b-form-input
                                      rows="2"
                                      v-model="train.field_visit_amount"
                                      disabled
                                      :placeholder="$t('elearning_tim.field_visit_amount')"
                                    ></b-form-input>
                                    <small v-if="paymentErrors && paymentErrors['payments.' + index + '.field_visit_amount']" class="text-danger">
                                      {{ paymentErrors['payments.'+index+'.field_visit_amount'] }}
                                    </small>
                                  </td>
                                  <td v-if="formData.org_id === 12">
                                    <b-form-input
                                      rows="2"
                                      v-model="train.field_visit_day"
                                      :placeholder="$t('elearning_tim.field_visit_day')"
                                    ></b-form-input>
                                    <small v-if="paymentErrors && paymentErrors['payments.' + index + '.field_visit_day']" class="text-danger">
                                      {{ paymentErrors['payments.'+index+'.field_visit_day'] }}
                                    </small>
                                  </td>
                                  <td>{{train.total_days * train.honour_amount + train.field_visit_amount * train.field_visit_day }}</td>
                                  <td>
                                    {{train.vat_tax_amount}}
                                  </td>
                                  <td>
                                    <b-form-input
                                      rows="2"
                                      v-model="train.other"
                                      :placeholder="$t('globalTrans.detuction')"
                                      @input="paymentAmountUpdate(train)"
                                    ></b-form-input>
                                    <small v-if="paymentErrors && paymentErrors['payments.' + index + '.other']" class="text-danger">
                                      {{ paymentErrors['payments.'+index+'.other'] }}
                                    </small>
                                  </td>
                                  <td>
                                    <b-form-input
                                      v-model="train.payment_amount"
                                      :placeholder="$t('globalTrans.payment_amount')"
                                      readonly
                                    ></b-form-input>
                                    <small v-if="paymentErrors && paymentErrors['payments.' + index + '.payment_amount']" class="text-danger">
                                      {{ paymentErrors['payments.'+index+'.payment_amount'] }}
                                    </small>
                                  </td>
                                </tr>
                                <template v-if="formData.payments.length === 0">
                                  <tr>
                                    <th colspan="10" class="text-center text-danger">{{$t('globalTrans.noDataFound')}}</th>
                                  </tr>
                                </template>
                              </table>
                            </b-overlay>
                        </fieldset>
                      </div>
                    </b-row>
                    <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
      <!-- <pre>{{formData}}</pre> -->
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { billPaymentStore, billPaymentPaymentSearch, circularPublication, circularList } from '../../api/routes'
import flatpickr from 'flatpickr'
const today = new Date().toISOString().substr(0, 10)

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    // this.getCircularList()
    if (this.$route.query.id) {
      this.getFormData()
    }
    if (this.$store.state.Auth.activeRoleId !== 1) {
      this.formData.org_id = this.$store.state.Auth.authUser.org_id
      if (this.$store.state.Auth.authUser.is_org_admin !== 1 && parseInt(this.$store.state.Auth.authUser.org_id) === 12) {
        this.formData.coordinator_id = this.$store.state.Auth.authUser.user_id
      }
    }
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      today: today,
      loading: false,
      load: false,
      saveBtnName: this.id ? this.$t('elearning_config.update') : this.$t('globalTrans.save'),
      formData: {
        payment_type: 'Trainee',
        coordinator_id: 0,
        fiscal_year_id: null,
        office_type_id: 0,
        office_id: 0,
        org_id: null,
        training_category_id: null,
        training_type_id: null,
        training_title_id: null,
        circular_memo_no: '',
        batch_no: 0,
        vat_tax: 0,
        honour_amount: 0,
        field_visit_amount: 0,
        field_visit_day: 0,
        training_status: 1,
        officer_allowance: 1,
        payment_date: today,
        payments: []
      },
      officeTypeList: [],
      training_start_date: '',
      training_end_date: '',
      payment: [],
      officeList: [],
      courseModuleList: [],
      allowanceList: [],
      courseList: [],
      circularList: [],
      batchList: [],
      allBatchListData: [],
      circularLoading: false,
      required: true,
      trainingTypeList: [],
      trainingCategoryList: [],
      attachments_errors: [],
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      trainerListData: [],
      trainingTitleList: [],
      paymentErrors: [],
      myFilter: (option, text, search) => {
                  const temp = search.toLowerCase()
                  return option.text_en.toLowerCase().indexOf(temp) > -1 ||
                  option.text_bn.toLowerCase().indexOf(temp) > -1
              }
    }
  },
  computed: {
    allBatchList () {
      return [
        { value: 1, text: this.$i18n.locale === 'en' ? '1st' : '১ম', text_en: '1st', text_bn: '১ম' },
        { value: 2, text: this.$i18n.locale === 'en' ? '2nd' : '২য়', text_en: '2nd', text_bn: '২য়' },
        { value: 3, text: this.$i18n.locale === 'en' ? '3rd' : '৩য়', text_en: '3rd', text_bn: '৩য়' },
        { value: 4, text: this.$i18n.locale === 'en' ? '4th' : '৪র্থ', text_en: '4th', text_bn: '৪র্থ' },
        { value: 5, text: this.$i18n.locale === 'en' ? '5th' : '৫ম', text_en: '5th', text_bn: '৫ম' },
        { value: 6, text: this.$i18n.locale === 'en' ? '6th' : '৬ষ্ঠ', text_en: '6th', text_bn: '৬ষ্ঠ' },
        { value: 7, text: this.$i18n.locale === 'en' ? '7th' : '৭ম', text_en: '7th', text_bn: '৭ম' },
        { value: 8, text: this.$i18n.locale === 'en' ? '8th' : '৮ম', text_en: '8th', text_bn: '৮য়' },
        { value: 9, text: this.$i18n.locale === 'en' ? '9th' : '৯ম', text_en: '9th', text_bn: '৯ম' },
        { value: 10, text: this.$i18n.locale === 'en' ? '10th' : '১০ম', text_en: '10th', text_bn: '১০ম' }
      ]
    },
    trainerList: function () {
      const listObject = this.trainerListData
      const tmpList = listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_en }
        } else {
          return { value: obj.value, text: obj.text_bn }
        }
      })
      return tmpList
    },
    venus: function () {
      return this.$store.state.TrainingElearning.commonObj.venus.filter(item => item.status === 1)
    },
    trainingQuarterSetupList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingQuarterSetupList.filter(item => item.status === 1)
    },
    paymentType: function () {
      return this.$store.state.TrainingElearning.commonObj.paymentType
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    }
  },
  watch: {
    'formData.org_id': function (newValue) {
      this.trainingTypeList = this.getTypeList(newValue)
    },
    'formData.office_type_id': function (newValue) {
      this.officeList = this.getOfficeList(newValue)
    },
    'formData.training_type_id': function (newValue) {
      this.trainingCategoryList = this.getCategoryList(newValue)
    },
    'formData.training_category_id': function (newValue) {
      this.trainingTitleList = this.getTrainingTitleList(newValue)
    },
    'formData.training_title_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getCircularList()
        // this.courseModuleList = this.getCourseModuleList(newVal)
      }
    },
    'formData.honour_amount': function (newValue, oldVal) {
      if (newValue !== oldVal) {
        // this.vatAddAll()
      }
    },
    'formData.vat_tax': function (newValue, oldVal) {
      if (newValue !== oldVal) {
        // this.vatAddAll()
      }
    },
    'formData.circular_memo_no': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        const allBatchListData = this.allBatchListData.find(allBatchListData => String(allBatchListData.circular_memo_no) === String(newVal))
        if (typeof allBatchListData !== 'undefined') {
          this.formData.batch_no = allBatchListData.batch_no
          this.training_start_date = allBatchListData.training_start_date
          this.training_end_date = allBatchListData.training_end_date
        }
      }
    },
    'formData.batch_no': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        const allBatchListData = this.allBatchListData.find(allBatchListData => parseInt(allBatchListData.batch_no) === parseInt(newVal))
        if (typeof allBatchListData !== 'undefined') {
          this.formData.circular_memo_no = allBatchListData.circular_memo_no
        }
      }
      this.getCircularMemoNo(newVal)
    }
    // 'formData.payment_date': function (newVal, oldVal) {
    //   if (newVal !== oldVal) {
    //     this.getTrainerSheduleList()
    //   }
    // }
  },
  methods: {
    getTypeList (orgId) {
      const type = this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
      if (orgId) {
        return type.filter(item => item.org_id === parseInt(orgId))
      }
      return type
    },
    batchInfo () {
      const batchData = this.allBatchListData.filter(allBatchList => parseInt(allBatchList.training_title_id) === parseInt(this.formData.training_title_id))
      const batchList = []
      batchData.map((obj, index) => {
        if (obj.batch_no) {
          const isThere = this.allBatchList.find(allBatchList => parseInt(allBatchList.value) === parseInt(obj.batch_no))
          if (typeof isThere !== 'undefined') {
            batchList.push(isThere)
          }
        }
      })
      this.batchList = batchList
      if (this.batchList.length === 1) {
        this.formData.batch_no = 1
      }
    },
    circluarList () {
      const listObject = this.allBatchListData.filter(allBatchListData => parseInt(allBatchListData.training_title_id) === parseInt(this.formData.training_title_id))
      const tmpList = listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
        } else {
          return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
        }
      })
      this.circularList = tmpList
    },
    async getCircularList () {
      this.circularLoading = true
      const serchData = {
        fiscal_year_id: this.formData.fiscal_year_id,
        training_type_id: this.formData.training_type_id,
        training_category_id: this.formData.training_category_id,
        training_title_id: this.formData.training_title_id,
        coordinator_id: this.formData.coordinator_id
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularList + '/' + 'iab_circular_publications', serchData)
      if (!result.success) {
        this.allBatchListData = []
      } else {
        this.allBatchListData = result.batchList
        this.batchInfo()
        this.circluarList()
      }
      this.circularLoading = false
    },
    // async getCircularList () {
    //   this.circularLoading = true
    //   const serchData = {
    //     org_id: this.$store.state.dataFilters.orgId
    //   }
    //   const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularPublicationList + '/' + 'tpm_training_calendar' + '/' + 1, serchData)
    //   if (!result.success) {
    //   } else {
    //      const listObject = result.data
    //     const tmpList = listObject.map((obj, index) => {
    //       if (this.$i18n.locale === 'bn') {
    //         return { value: obj.circular_memo_no, text: obj.circular_memo_no }
    //       } else {
    //         return { value: obj.circular_memo_no, text: obj.circular_memo_no }
    //       }
    //     })
    //     this.circularList = tmpList
    //   }
    //   this.circularLoading = false
    // },
    async getCircularMemoNo () {
      this.load = true
      const params = {
        circular_memo_no: this.formData.circular_memo_no,
        batch_no: this.formData.batch_no,
        table: 'iab_circular_publications'
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularPublication, params)
      if (!result.success) {
        this.formData.fiscal_year_id = 0
        this.formData.org_id = 0
        this.formData.office_id = 0
        this.formData.office_type_id = 0
        this.formData.training_title_id = 0
        this.formData.training_type_id = 0
        this.formData.training_category_id = 0
        this.formData.honour_amount = 0
        this.formData.vat_tax = 0
        this.formData.payment_date = 0
      } else {
        this.lcErrorMsg = ''
        const trData = result.data
        this.formData.fiscal_year_id = trData.fiscal_year_id
        this.formData.org_id = trData.org_id
        this.formData.office_id = trData.office_id
        this.formData.office_type_id = trData.office_type_id
        this.formData.training_title_id = trData.training_title_id
        this.formData.training_type_id = trData.training_type_id
        this.formData.training_category_id = trData.training_category_id
        // this.formData.honour_amount = trData.honour_amount
        // this.formData.vat_tax = trData.vat_tax
        // this.formData.payment_date = trData.payment_date
        this.getTrainerSheduleList()
      }
      this.load = false
    },
    // vatAddAll () {
    //   const payments = this.formData.payments.map(item => {
    //     const amountData = {}
    //     item.vat_tax = this.formData.vat_tax
    //     item.honour_amount = this.formData.honour_amount
    //     const paymentCalculate = this.paymentAmountUpdate(item)
    //       amountData.vat_tax_amount = paymentCalculate.vat_tax_amount
    //       amountData.payment_amount = paymentCalculate.payment_amount
    //       return Object.assign({}, item, amountData)
    //   })
    //   this.formData.payments = payments
    // },
    vatAddAll () {
      const payments = this.formData.payments.map(item => {
        const amountData = {}
        item.vat_tax = this.formData.vat_tax
        item.honour_amount = this.formData.honour_amount
        item.field_visit_day = this.formData.field_visit_day
        item.field_visit_amount = this.formData.field_visit_amount
        const paymentCalculate = this.paymentAmountUpdate(item)
        amountData.vat_tax_amount = paymentCalculate.vat_tax_amount
        amountData.payment_amount = paymentCalculate.payment_amount
        return Object.assign({}, item, amountData)
      })
      this.formData.payments = payments
    },
    // paymentAmountUpdate (train) {
    //   const amount = parseFloat(train.honour_amount) * parseFloat(train.total_days)
    //   train.vat_tax_amount = this.calculatePercentage(amount, train.vat_tax)
    //   train.payment_amount = (parseFloat(amount) - parseFloat(train.vat_tax_amount) - parseFloat(train.other)) - parseFloat(train.detucton)
    //   return train
    // },
    paymentAmountUpdate (train) {
      const amount = parseFloat(train.honour_amount) * parseFloat(train.total_days) + parseFloat(train.field_visit_amount) * parseFloat(train.field_visit_day)
      train.vat_tax_amount = this.calculatePercentage(amount, train.vat_tax)
      train.payment_amount = (parseFloat(amount) - parseFloat(train.vat_tax_amount) - parseFloat(train.other)) - parseFloat(train.detucton)
      return train
    },
    calculatePercentage (oldAvg, newAvg) {
      const totalPercentage = (parseFloat(oldAvg) * newAvg) / 100
      return totalPercentage
    },
    removeAttachment (index, data) {
      this.formData.module_attachments.splice(index, 1)
      if (data.pre_attachment) {
        this.formData.module_attachments_remove.push(data)
      }
    },
    getOfficeTypeList (orgId) {
      const OfficeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
      if (orgId) {
        return OfficeTypeList.filter(item => item.org_id === orgId)
      }
      return OfficeTypeList
    },
    getOfficeList (officeTypeId) {
      const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
      if (officeTypeId) {
        return officeList.filter(item => item.office_type_id === officeTypeId)
      }
      return officeList
    },
    getCategoryList (typeId) {
       const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
       if (typeId) {
         return trainingCategoryList.filter(item => item.training_type_id === typeId)
       }
       return trainingCategoryList
    },
    getTrainingTitleList (categoryId) {
      const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
      if (categoryId) {
        return trainingTitleList.filter(item => item.training_category_id === categoryId)
      }
      return trainingTitleList
    },
    async getTrainerSheduleList () {
      // if (this.formData.training_title_id && this.formData.training_category_id && this.formData.training_type_id && this.formData.batch_no && this.formData.payment_date) {
      if (this.formData.training_title_id && this.formData.training_category_id && this.formData.training_type_id && this.formData.batch_no) {
        this.loading = true
        const data = this.formData
        data.payments = []
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, billPaymentPaymentSearch, data)
        if (!result.success) {
          this.formData.payments = []
          this.loading = false
          this.required = true
          if (result.success) {
            // if (result.grade) {
              this.$toast.success({
                title: 'Success',
                message: result.message,
                color: '#D6E09B'
              })
            // }
          } else {
            this.$toast.error({
              title: 'Error',
              message: this.$t('globalTrans.noDataFound'),
              color: '#D6E09B'
            })
          }
        } else {
          const resultData = result.data
          this.payment = result.payment
          this.allowanceList = result.allowanceList
          if (result.main) {
            this.formData.honour_amount = result.main.honour_amount
            this.formData.field_visit_amount = result.main.field_visit_amount
            this.formData.field_visit_day = result.main.field_visit_day
            // this.formData.payment_date = result.main.payment_date
            this.formData.vat_tax = result.main.vat_tax
          }
          const payments = []
          resultData.map(item => {
            const desigObj = this.$store.state.commonObj.designationList.find(designation => designation.value === parseInt(item.designation_id))
            const desigData = {}
            if (typeof desigObj !== 'undefined') {
              desigData.designation_name = desigObj.text_en
              desigData.designation_name_bn = desigObj.text_bn
            } else {
              desigData.designation_name = ''
              desigData.designation_name_bn = ''
            }
            const officeObj = this.$store.state.commonObj.officeList.find(doc => doc.value === parseInt(item.professional_office_id))
            const officeData = {}
            if (typeof officeObj !== 'undefined') {
              officeData.office = officeObj.text_en
              officeData.office_bn = officeObj.text_bn
            } else {
              officeData.office = ''
              officeData.office_bn = ''
            }
            const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.professional_org_id))
            const orgData = {}
            if (typeof orgObj !== 'undefined') {
              orgData.org = orgObj.text_en
              officeData.org_bn = orgObj.text_bn
            } else {
              orgData.org = ''
              orgData.org_bn = ''
            }
            const paymentObj = this.payment.find(payment => payment.training_application_id === parseInt(item.id) && payment.training_title_id === parseInt(item.training_title_id))
            const paymentData = {}
            paymentData.training_application_id = item.id
            const allowanceListObjData = this.allowanceList.find(allowanceListObj => allowanceListObj.training_application_id === parseInt(item.id))
            if (typeof allowanceListObjData !== 'undefined') {
              paymentData.total_days = allowanceListObjData.total_days
              if (typeof paymentObj !== 'undefined') {
                paymentData.id = paymentObj.id
                paymentData.honour_amount = paymentObj.honour_amount
                paymentData.field_visit_amount = paymentObj.field_visit_amount
                paymentData.field_visit_day = paymentObj.field_visit_day
                paymentData.vat_tax = paymentObj.vat_tax
                paymentData.vat_tax_amount = paymentObj.vat_tax_amount
                paymentData.other = paymentObj.other
                paymentData.detucton = paymentObj.detucton
                paymentData.payment_amount = paymentObj.payment_amount
                // paymentData.payment_date = paymentObj.payment_date
                paymentData.comments = paymentObj.comments
                let disbledPayment = false
                if (paymentObj.payment_status === 2) {
                  disbledPayment = true
                }
                paymentData.payment_disabled = disbledPayment
                this.required = false
              } else {
                paymentData.id = 0
                paymentData.honour_amount = 0
                paymentData.vat_tax = 0
                paymentData.vat_tax_amount = 0
                paymentData.other = 0
                paymentData.detucton = 0
                paymentData.payment_amount = 0
                paymentData.comments = ''
                paymentData.payment_date = today
                paymentData.payment_disabled = false
              }
              const checkData = Object.assign({}, item, desigData, paymentData, officeData, orgData)
              payments.push(checkData)
            }
          })
          this.formData.payments = payments
          this.loading = false
        }
      }
    },
    async createData () {
      this.loading = true
      let result = null
      result = await RestApi.postData(trainingElearningServiceBaseUrl, billPaymentStore, this.formData)
      this.loading = false
      if (result.success) {
        this.$toast.success({
            title: 'Success',
            message: this.id ? this.$t('elearning_config.update_msg') : this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
        })
        this.getTrainerSheduleList()
        this.$router.push('/training-e-learning-service/tim/allowance')
      } else {
        this.paymentErrors = result.errors
        this.$refs.form.setErrors(result.errors)
        // this.$refs.form.setErrors(result.errors)
      }
  }
  }
}
</script>
